import React from 'react';

import dictionary from '../data/_dictionary';
import publishers from '../data/_publishers';
import fb from '../images/fb.png';
import insta from '../images/insta.png';
import tt from '../images/tt.png';

const Footer = ({lang}) => (
    <div className="footer">
        <div className="footer-left">
            <a className="footer-left-navigation-media-link" href={dictionary._linkFB[lang]} target="_blank" rel="noopener noreferrer">
                <img className="footer-left-navigation-media-link-icon" src={fb} alt="fb" />
            </a>
            <a className="footer-left-navigation-media-link" href={dictionary._linkInsta[lang]} target="_blank" rel="noopener noreferrer">
                <img className="footer-left-navigation-media-link-icon" src={insta} alt="instagram" />
            </a>
            <a className="footer-left-navigation-media-link" href={dictionary._linkTT[lang]} target="_blank" rel="noopener noreferrer">
                <img className="footer-left-navigation-media-link-icon" src={tt} alt="tt" />
            </a>
        </div>
        <div className="footer-navigation">
            <ul className="footer-navigation-list">
                {publishers.map(({label, path}, i) => (
                    <li key={i} className="footer-navigation-list-item">
                        <a href={path} target="_blank" rel="noopener noreferrer">{label}</a>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

export default Footer;

const navigation = [
    {
        name: 'about',
        path: {
            en: '/en/about',
            de: '/de/about',
            pl: '/pl/about',
        },
        label: {
            en: 'About the journal',
            de: 'Über die Zeitschrift',
            pl: 'O czasopiśmie',
        },
    },
    {
        name: 'scope',
        path: {
            en: '/en/scope',
            de: '/de/scope',
            pl: '/pl/scope',
        },
        label: {
            en: 'Aims & Scope',
            de: 'Zielsetzung und Umfang',
            pl: 'Cele i zakres',
        },
    },
    {
        name: 'current',
        path: {
            en: '/en/current',
            de: '/de/current',
            pl: '/pl/current',
        },
        label: {
            en: 'Current issue',
            de: 'Aktuelle Ausgabe',
            pl: 'Bieżący numer',
        },
    },
    {
        name: 'next',
        path: {
            en: '/docs/next_issue_en.pdf',
            de: '/docs/next_issue_de.pdf',
            pl: '/docs/next_issue_en.pdf',
        },
        label: {
            en: 'Next issue',
            de: 'Nächste Ausgabe',
            pl: 'Następny numer',
        },
        doc: true,
    },
    {
        name: 'editorial-staff',
        path: {
            en: '/en/editorial-staff',
            de: '/de/editorial-staff',
            pl: '/pl/editorial-staff',
        },
        label: {
            en: 'Editorial Staff',
            de: 'Redaktion',
            pl: 'Redakcja',
        },
    },
    {
        name: 'editorial-board',
        path: {
            en: '/en/editorial-board',
            de: '/de/editorial-board',
            pl: '/pl/editorial-board',
        },
        label: {
            en: 'Editorial Board',
            de: 'Redaktionsbeirat',
            pl: 'Rada Redakcyjna',
        },
    },
    {
        name: 'scientific-board',
        path: {
            en: '/en/scientific-board',
            de: '/de/scientific-board',
            pl: '/pl/scientific-board',
        },
        label: {
            en: 'Scientific Board',
            de: 'Wissenschaftlicher Beirat',
            pl: 'Rada Naukowa',
        },
    },
    {
        name: 'make-submission',
        path: {
            en: '/en/make-submission',
            de: '/de/make-submission',
            pl: '/pl/make-submission',
        },
        label: {
            en: 'Make a submission',
            de: 'Beitrag einreichen',
            pl: 'Zgłoś tekst',
        },
    },
    {
        name: 'authors-info',
        path: {
            en: '/en/authors-info',
            de: '/de/authors-info',
            pl: '/pl/authors-info',
        },
        label: {
            en: 'Information for authors',
            de: 'Für Autoren',
            pl: 'Informacje dla Autorów',
        },
    },
    {
        name: 'authors',
        path: {
            en: '/en/authors',
            de: '/de/authors',
            pl: '/pl/authors',
        },
        label: {
            en: 'List of authors',
            de: 'Index der Autoren',
            pl: 'Baza Autorów',
        },
    },
    {
        name: 'reviewers-info',
        path: {
            en: '/en/reviewers-info',
            de: '/de/reviewers-info',
            pl: '/pl/reviewers-info',
        },
        label: {
            en: 'Information for reviewers',
            de: 'Für Gutachter',
            pl: 'Informacje dla Recenzentów',
        },
    },
    {
        name: 'reviewers',
        path: {
            en: '/en/reviewers',
            de: '/de/reviewers',
            pl: '/pl/reviewers',
        },
        label: {
            en: 'Board of Reviewers',
            de: 'Gutachterbeirat',
            pl: 'Rada Recenzentów',
        },
    },
    {
        name: 'review-process',
        path: {
            en: '/en/review-process',
            de: '/de/review-process',
            pl: '/pl/review-process',
        },
        label: {
            en: 'Review procedure',
            de: 'Begutachtungsverfahren',
            pl: 'Procedura recenzowania',
        },
    },
    {
        name: 'ethics',
        path: {
            en: '/en/ethics',
            de: '/de/ethics',
            pl: '/pl/ethics',
        },
        label: {
            en: 'Ethical standards',
            de: 'Ethische Standards',
            pl: 'Standardy etyczne',
        },
    },
    {
        name: 'evaluation',
        path: {
            en: '/en/evaluation',
            de: '/de/evaluation',
            pl: '/pl/evaluation',
        },
        label: {
            en: 'Scoring and indexing',
            de: 'Punkte und Indexierung',
            pl: 'Punktacja i indeksacja',
        },
    },
    {
        name: 'reviews',
        path: {
            en: '/en/reviews',
            de: '/de/reviews',
            pl: '/pl/reviews',
        },
        label: {
            en: 'Others about us',
            de: 'Wissenschaftliche Rezensionen der Zeitschrift',
            pl: 'Napisali o nas',
        },
    },
    {
        name: 'keywords',
        path: {
            en: '/en/keywords',
            de: '/de/keywords',
            pl: '/pl/keywords',
        },
        label: {
            en: 'Keyword database',
            de: 'Index der Schlüsselwörter',
            pl: 'Baza słów kluczowych',
        },
    },
    {
        name: 'areas',
        path: {
            en: '/en/areas',
            de: '/de/areas',
            pl: '/pl/areas',
        },
        label: {
            en: 'Research areas',
            de: 'Forschungsbereiche',
            pl: 'Obszary badawcze',
        },
    },
    {
        name: 'archive',
        path: {
            en: '/en/archive',
            de: '/de/archive',
            pl: '/pl/archive',
        },
        label: {
            en: 'Archives',
            de: 'Archiv',
            pl: 'Archiwum',
        },
    },
    {
        name: 'contact',
        path: {
            en: '/en/contact',
            de: '/de/contact',
            pl: '/pl/contact',
        },
        label: {
            en: 'Contact',
            de: 'Kontakt',
            pl: 'Kontakt',
        },
    },
    {
        name: 'newsletter',
        path: {
            en: '/en/newsletter',
            de: '/de/newsletter',
            pl: '/pl/newsletter',
        },
        label: {
            en: 'Newsletter',
            de: 'Newsletter',
            pl: 'Newsletter',
        },
    },
];

export default navigation;

const publishers = [
    {
        label: 'Oficyna Wydawnicza ATUT',
        path: 'http://www.atut.ig.pl',
    },
    {
        label: 'Neisse Verlag',
        path: 'https://www.neisseverlag.de',
    },
    {
        label: 'Uniwersytet Wrocławski',
        path: 'https://uni.wroc.pl',
    },
];

export default publishers;

const dictionary = {
    _linkOpenAccess: {
        en: 'https://en.wikipedia.org/wiki/Open_access',
        de: 'https://de.wikipedia.org/wiki/Open_Access',
        pl: 'https://pl.wikipedia.org/wiki/Otwarty_dostęp',
    },
    _linkCreativeCommons: {
        en: 'https://creativecommons.org/licenses/by-sa/4.0/deed.en',
        de: 'https://creativecommons.org/licenses/by-sa/4.0/deed.de',
        pl: 'https://creativecommons.org/licenses/by-sa/4.0/deed.pl',
    },
    _linkFB: {
        en: 'https://www.facebook.com/linguistischetreffeninwroclaw',
        de: 'https://www.facebook.com/linguistischetreffeninwroclaw',
        pl: 'https://www.facebook.com/linguistischetreffeninwroclaw',
    },
    _linkInsta: {
        en: 'https://instagram.com/linguistischetreffeninwroclaw?igshid=k7xpqkse108o',
        de: 'https://instagram.com/linguistischetreffeninwroclaw?igshid=k7xpqkse108o',
        pl: 'https://instagram.com/linguistischetreffeninwroclaw?igshid=k7xpqkse108o',
    },
    _linkTT: {
        en: 'https://twitter.com/LTWroclaw',
        de: 'https://twitter.com/LTWroclaw',
        pl: 'https://twitter.com/LTWroclaw',
    },
    abstracts: {
        en: 'Abstracts',
        de: 'Abstracts',
        pl: 'Abstrakty',
    },
    archive: {
        en: 'Archives',
        de: 'Archiv',
        pl: 'Archiwum',
    },
    audioFile: {
        en: 'Audio file',
        de: 'Tonbeispiel',
        pl: 'Plik dźwiękowy',
    },
    authorsIndex: {
        en: 'List of authors',
        de: 'Index der Autoren',
        pl: 'Baza Autorów',
    },
    bounceRate: {
        en: 'Bounce Rate',
        de: 'Absprungrate',
        pl: 'Współczynnik odrzuceń',
    },
    contents: {
        en: 'Contents',
        de: 'Inhalt',
        pl: 'Spis treści',
    },
    creativeCommonsInfo: {
        en: 'Texts published in the ‘Linguistische Treffen in Wrocław’ are available in the Open Access on the basis of the CC BY-SA license.',
        de: 'Texte, die in der Zeitschrift „Linguistische Treffen in Wrocław“ veröffentlicht werden, stehen allen Nutzern im Open Access auf Grund der Lizenz CC BY-SA zur Verfügung.',
        pl: 'Teksty publikowane w czasopiśmie „Linguistische Treffen in Wrocław” są udostępniane w wolnym dostępie (Open Access) na podstawie licencji CC BY-SA.',
    },
    issue: {
        en: 'Issue',
        de: 'Heft',
        pl: 'Numer',
    },
    keywords: {
        en: 'Keywords',
        de: 'Schlüsselwörter',
        pl: 'Słowa kluczowe',
    },
    keywordsIndex: {
        en: 'Keyword database',
        de: 'Index der Schlüsselwörter',
        pl: 'Baza słów kluczowych',
    },
    newsletter: {
        en: 'Newsletter',
        de: 'Newsletter',
        pl: 'Newsletter',
    },
    pp: {
        en: 'pp.',
        de: 'S.',
        pl: 's.',
    },
    publishedOnline: {
        en: 'published online',
        de: 'online zugänglich',
        pl: 'data publikacji online',
    },
    noResults: {
        en: 'No results',
        de: 'Keine Ergebnisse',
        pl: 'Brak wyników',
    },
    reviewers: {
        en: 'Board of Reviewers',
        de: 'Gutachterbeirat',
        pl: 'Rada Recenzentów',
    },
    searchResultsFor: {
        en: 'Search results for “*”',
        de: 'Suchergebnisse für „*“',
        pl: 'Wyniki wyszukiwania dla „*”',
    },
    searchByAuthorResultsFor: {
        en: 'Search results for “*” (author)',
        de: 'Suchergebnisse für „*“ (Author)',
        pl: 'Wyniki wyszukiwania dla „*” (autor)',
    },
    searchByKeywordResultsFor: {
        en: 'Search results for “*” (keyword)',
        de: 'Suchergebnisse für „*“ (Schlüsselwort)',
        pl: 'Wyniki wyszukiwania dla „*” (słowo kluczowe)',
    },
};

export default dictionary;

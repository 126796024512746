import React from 'react';
import {Link, navigate} from 'gatsby';

import navigation from '../data/_navigation';

export default class Navigation extends React.Component {
  handleSubmit = event => {
    event.preventDefault();
    const query = event.currentTarget[0].value;
    navigate(`/${this.props.lang}/search?query=${query}`);
  }

  render() {
    const {lang, path} = this.props;

    return (
        <div className="navigation">

            <div className="navigation-search">
                <form onSubmit={this.handleSubmit}>
                    <input
                      type="text"
                      name="query"
                      placeholder=""
                    />
                    <button type="submit"><i className="icon-search" /></button>

                </form>
            </div>

            <ul className="navigation-list">
                {navigation.map((item, i) => (
                    <li key={i} className={`navigation-list-item ${item.path[lang] === path && 'enabled'}`}>
                        {item.doc ? (
                            <a href={item.path[lang]}>{item.label[lang]}</a>
                        ) : (
                            <Link to={item.path[lang]}>{item.label[lang]}</Link>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    )
  }
}

import React from 'react';
import {Link} from 'gatsby';

import open_access from '../images/open_access_white.png';

import dictionary from '../data/_dictionary';
import locales from '../data/_locales';

const Header = ({lang, path}) => (
    <div className="header">
        <div className="header-left">
            <h1>• Linguistische Treffen in Wrocław •</h1>
            <p>{`ISSN: 2084-3062 • e-ISSN: 2657-5647 • DOI: 10.23817/lingtreff • ${dictionary.bounceRate[lang]}: 35% (2023)`}</p>
        </div>
        <div className="header-navigation">
            <a className="header-navigation-link" href={dictionary._linkOpenAccess[lang]} target="_blank" rel="noopener noreferrer">
                <img  className="header-navigation-logo" src={open_access} alt="open access" />
            </a>
            <ul className="header-navigation-list">
                {locales.map((locale, i) => (
                    <li key={i} className={`header-navigation-list-item ${locale.lang === lang && 'enabled'}`}>
                        <Link to={path && path.replace(`/${lang}/`, `/${locale.lang}/`)}>{locale.label}</Link>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

export default Header;
